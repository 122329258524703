import { Box, Grid, Typography } from "@mui/material"
import { ISettingsItem } from "../Settings"

export default function BenefitsList({ name, variables }: ISettingsItem) {
  const { text = [] } = variables || {}

  return (
    <Grid
      item
      className={name ? `${name} benefits-list` : "benefits-list"}
    >
      {text.map((text, index) => (
        <Box
          key={index}
          className="bnfts-item"
        >
          <Typography
            variant="body2"
            dangerouslySetInnerHTML={{ __html: text }}
          />
        </Box>
      ))}
    </Grid>
  )
}
