import { Box, Slider } from "@mui/material"
import { PlansContext } from "../Plans"

export interface MarkProps {
  id: number
  value: number
  label: string
}

interface SliderComponentProps {
  marks: MarkProps[]
  currentValue: number
  setCurrentValue: (updatedValues: Partial<PlansContext>) => void
}

export default function SliderComponent({ marks, currentValue, setCurrentValue }: SliderComponentProps) {

  const updatedMarks = marks.map((mark) => ({
    ...mark,
    label: mark.value === currentValue ? mark.label : undefined,
  }))

  return (
    <Box className="slider-component">
      <Slider
        aria-label="Restricted values"
        value={currentValue}
        onChange={(_, value) => setCurrentValue({ currentSliderValue: value as number })}
        step={null}
        valueLabelDisplay="off"
        marks={updatedMarks}
      />
    </Box>
  )
}
