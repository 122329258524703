import { useEffect, useState } from "react"
import { Grid } from "@mui/material"
import { ISettingsItem } from "../../../Settings"
import { ReactComponent as HamburgerIcon } from '../../../assets/icons/hamburgerIcon.svg'
import { ReactComponent as CloseIcon } from '../../../assets/icons/closeIcon.svg'
import { usePlan } from "../../../Plans"

export default function OrderSummaryText({ name, variables }: ISettingsItem) {
  const { planBonus } = usePlan()
  const [windowWidth, setWindowWidth] = useState(window.innerWidth)

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth)
    }

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const [summaryOpen, setSummaryOpen] = useState(windowWidth >= 900)

  const isSummaryOpen = summaryOpen ? 'open' : 'closed'
  const defaultTextArray = ['Resumo do seu pedido', 'Detalhes do seu pedido']
  const { text = defaultTextArray } = variables || {}
  const elementsToHide = [
    'content-right-first-summary',
    'content-right-first-summary-portability',
    'content-right-first-summary-smart-control',
    'content-right-second-summary',
    'content-right-chosen-plan-chip'
  ]

  const showElements = () => {
    elementsToHide.forEach((elementId) => {
      const element = document.getElementById(elementId)
      if (element) {
        element.style.display = 'flex'
      }
    })
  }

  const hideElements = () => {
    elementsToHide.forEach((elementId) => {
      const element = document.getElementById(elementId)
      if (element) {
        element.style.display = 'none'
      }
    })
  }

  if (summaryOpen) {
    showElements()
  } else if (!summaryOpen) {
    hideElements()
  }

  if (!planBonus) {
    const element = document.getElementById('content-right-first-summary')
    if (element) {
      element.style.display = 'none'
    }
  }

  return (
    <Grid item className={name ? `${name} order-summary-text ${isSummaryOpen}` : `order-summary-text ${isSummaryOpen}`} xs={12}>
      <div className="textArea">
        <h1>{text[0]}</h1>
        <p>{text[1]}</p>
      </div>

      <div className="iconsArea">
        {
          !summaryOpen ?
            <HamburgerIcon onClick={() => setSummaryOpen(true)} />
            :
            <CloseIcon onClick={() => setSummaryOpen(false)} />
        }
      </div>
    </Grid>
  )
}