import { Button, Grid } from '@mui/material'
import { useCategory } from '../../../Categories'
import { ISettingsItem } from '../../../Settings'
import { ReactComponent as GamerIcon } from '../../../assets/icons/categories/ctgr-gamer.svg'
import { ReactComponent as BusinessIcon } from '../../../assets/icons/categories/ctgr-business.svg'
import { ReactComponent as InfluencerIcon } from '../../../assets/icons/categories/ctgr-influencer.svg'
import { ReactComponent as StudentIcon } from '../../../assets/icons/categories/ctgr-student.svg'
import { ReactComponent as KidsIcon } from '../../../assets/icons/categories/ctgr-kids.svg'

export default function SelectCategory({ name }: ISettingsItem) {
  const { categories, ctgrIndex, updateState } = useCategory()
  
  const ctgrIcons = {
    KIDS: KidsIcon,
    ESTUDANTE: StudentIcon,
    GAMER: GamerIcon,
    BUSINESS: BusinessIcon,
    INFLUENCER: InfluencerIcon
  }

  return (
    <Grid container item className={name ? `${name} select-category` : "select-category"} xs={12}>
      <Grid container item className="selectArea" xs={12}>
        {categories.map(({ nome }, index) => {
          const Icon = ctgrIcons[nome as keyof typeof ctgrIcons]
          let isActive = index === ctgrIndex
          return Icon ? (
            <Button
              key={index}
              className={`${isActive ? "activated" : ""} ctgr-${nome}`}
              onClick={() => updateState({ ctgrIndex: index })}
            >
              <Icon />
            </Button>
          ) : null
        })}
      </Grid>
    </Grid>
  )
}