import { PropsWithChildren, createContext, useContext, useEffect, useState } from "react"
import { useLocation } from "react-router-dom"
import requests from "./api/client"

export interface Category {
  id: string
  nome: string
  detalhes: string
  beneficios: string[]
  aplicativos: string[]
}

export interface CategoriesContext {
  categories: Category[]
  currentCategory: Category
  ctgrClass: string,
  ctgrIndex: number,
  updateState: (updatedValues: Partial<CategoriesContext>) => void
}

const defaultCategory = {
  "id": "0",
  "nome": "",
  "detalhes": "",
  "beneficios": [],
  "aplicativos": [],
}

const defaultCategoriesData: CategoriesContext = {
  categories: [defaultCategory, defaultCategory],
  currentCategory: defaultCategory,
  ctgrClass: 'GAMER',
  ctgrIndex: 0,
  updateState: () => {},
}

const CategoryContext = createContext<CategoriesContext>(defaultCategoriesData)

export function useCategory() {
  return useContext(CategoryContext)
}

export function CategoryProvider({ children }: PropsWithChildren) {
  const [currentState, setCurrentState] = useState<CategoriesContext>(defaultCategoriesData)
  const [currentPath, setCurrentPath] = useState<string>()
  const location = useLocation()
  const updateState = (updatedValues: Partial<CategoriesContext>) => {
    setCurrentState(prevState => ({
      ...prevState,
      ...updatedValues
    }))
  }
  
  useEffect(() => {
    const param = location.pathname.split('/')[1]
    setCurrentPath(param)
  }, [location, currentPath])

  useEffect(() => {
    async function fetchCategories() {
      try {
        const categoriesArray = await requests.categoriesList()

        if (Array.isArray(categoriesArray)) {
          setCurrentState((prevState) => ({
            ...prevState,
            categories: categoriesArray,
            currentCategory: prevState.currentCategory.id === defaultCategory.id
              ? categoriesArray[0]
              : prevState.currentCategory,
          }))  
        } else {
          console.error('Resposta inesperada da API:', categoriesArray)
        }
      } catch (error) {
        console.error('Erro ao buscar planos:', error)
      }
    }

    if (currentPath === '' || currentPath === 'vip') fetchCategories()
  }, [currentPath])

  return (
    <CategoryContext.Provider value={{ ...currentState, updateState }}>
      {children}
    </CategoryContext.Provider>
  )
}