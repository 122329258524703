import { Box, Typography } from "@mui/material"
import Heading from "./page1/left/Heading"

export default function Page404() {
  return (
    <Box
      className="page404"
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        minHeight: '100vh',
        backgroundColor: 'var(--primaryColor)',
      }}
    >
      <Heading />
      <Typography className="404" variant="h1" style={{ color: 'var(--secondaryColor)' }}>
        404
      </Typography>
      <Typography className="pageNotFound" variant="h6" style={{ color: 'var(--secondaryColor)' }}>
        Página não encontrada
      </Typography>
    </Box>
  )
}