import { Grid, Hidden } from "@mui/material"
import { ISettingsItem } from "../../../Settings"
import { usePlan } from '../../../Plans'
import { ReactComponent as WhatsappIcon } from '../../../assets/icons/whatsappIcon.svg'
import { ReactComponent as PhoneIcon } from '../../../assets/icons/phoneIcon.svg'
import { ReactComponent as RefreshIcon } from '../../../assets/icons/refreshIcon.svg'
import { ReactComponent as CellTowerIcon } from '../../../assets/icons/cellTower.svg'
import { ReactComponent as CheckIcon } from '../../../assets/icons/checkIcon.svg'
import { ReactComponent as CoverageIcon } from '../../../assets/icons/coverage.svg'
import { ReactComponent as WazeIcon } from '../../../assets/icons/wazeIcon.svg'

export default function PlanDetailsText({ name, variables }: ISettingsItem) {
  const { currentPlan } = usePlan()
  const { text } = variables || {}

  const ligacoes = currentPlan.ligacoes === 'ligações ilimitadas' ? 'Ligações ilimitadas' : '60min de ligações'
  const defaultTextArray = [
    'WhatsApp liberado',
    ligacoes,
    'Rede 5G',
    'Internet que acumula',
    `${currentPlan.plano} sem cortes`,
    'Cobertura nacional',
  ]

  const mobileTextArray = [
    'Incluso no seu plano',
    'Rede 5G',
    'Tecnologia',
    'Acumule',
    'Acúmulo de internet',
    'Sem Cortes',
    'Internet',
    'Bônus',
    'Todo mês'
  ]

  const mergedArray = text
    ? text.map((value, index) => (value !== undefined && value !== '' ? value : defaultTextArray[index]))
    : defaultTextArray

  const planSizeMatch = currentPlan.plano.match(/\d+/)
  const planSize = planSizeMatch ? parseInt(planSizeMatch[0], 10) : 0

  return (
    <Grid container item className={name ? `${name} plan-details-text` : "plan-details-text"} xs={12}>
      <Hidden mdUp>
        <h1>{mobileTextArray[0]}</h1>
        <ul>
          <li>
            <h1>{mobileTextArray[1]}</h1>
            <p>{mobileTextArray[2]}</p>
          </li>
          <li>
            <h1>{mobileTextArray[3]}</h1>
            <p>{mobileTextArray[4]}</p>
          </li>
          <li>
            <h1>{mobileTextArray[5]}</h1>
            <p>{mobileTextArray[6]}</p>
          </li>
          <li className="plan-details-text-bonus">
            <h1>{mobileTextArray[7]}</h1>
            <p>{mobileTextArray[8]}</p>
          </li>
        </ul>
      </Hidden>
      <Hidden mdDown>
        <Grid item className="planDetailsTextColumn planDetailsTextLeft" xs={12} md={6} rowGap={1}>
          <div className="planDetailsTextDetail icon">
            <WhatsappIcon />
            <p>{mergedArray[0]}</p>
          </div>
          <div className="planDetailsTextDetail icon">
            <PhoneIcon />
            <p>{mergedArray[1]}</p>
          </div>
          <div className="planDetailsTextDetail cellTowerIcon">
            <CellTowerIcon />
            <p>{mergedArray[2]}</p>
          </div>
          {planSize >= 15 && (
            <div className="planDetailsTextDetail icon">
              <WazeIcon />
              <p>Waze ilimitado</p>
            </div>
          )}
        </Grid>
        <Grid item className="planDetailsTextColumn planDetailsTextRight" xs={12} md={6} rowGap={3}>
          <div className="planDetailsTextDetail icon">
            <RefreshIcon />
            <p>{mergedArray[3]}</p>
          </div>
          <div className="planDetailsTextDetail checkIcon">
            <CheckIcon />
            <p>{mergedArray[4]}</p>
          </div>
          <div className="planDetailsTextDetail icon">
            <CoverageIcon />
            <p>{mergedArray[5]}</p>
          </div>
        </Grid>
      </Hidden>
    </Grid>
  )
}
