import { useEffect, useState } from 'react'
import { BrowserRouter } from 'react-router-dom'
import { CircularProgress, Grid } from '@mui/material'
import { InitReqProvider } from './InitReq'
import { SettingsProvider } from './Settings'
import { CategoryProvider } from './Categories'
import { PlanProvider } from './Plans'
import { FormProvider } from './Form'
import requests from './api/client'
import Layout from './layouts'

function App() {
  const [loadingToken, setLoadingToken] = useState(true)

  useEffect(() => {
    async function fetchToken() {
      const { retorno } = await requests.tokenAuthentication()
      if (retorno) setLoadingToken(false)
    }
    fetchToken()
  },[])

  return (
    <BrowserRouter>
      <SettingsProvider>
        <FormProvider>
          {
            loadingToken ? (
              <Grid
                container
                sx={{
                  width: '100vw',
                  height: '100vh',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center'
                  }}
                >
                <CircularProgress />
              </Grid>
            ) : (
              <InitReqProvider>
                <CategoryProvider>
                  <PlanProvider>
                    <Layout />
                  </PlanProvider>
                </CategoryProvider>
              </InitReqProvider>
            )
          }
        </FormProvider>
      </SettingsProvider>
    </BrowserRouter>
  )
}

export default App
