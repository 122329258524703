import { PropsWithChildren, createContext, useCallback, useContext, useEffect, useState } from "react"
import { useLocation } from "react-router-dom"
import requests from "./api/client"

export interface Plan {
  id: number
  plano: string
  categoria?: string
  categoria_id?: string
  valor: number
  nome_plano: string
  ligacoes: string
  sms: string
  detalhamento: string
  descricao_detalhamento: string
  cor: string
  internet_padrao: string
}

export interface PlansContext {
  plans: Plan[]
  currentPlan: Plan
  planClass: string
  monthlyPrice: number
  annualPrice: number
  chosenPlan: string
  virtualChip: boolean
  portability: boolean
  smartControl: boolean
  planConfigs: boolean
  planBonus: boolean
  planIndex: number
  step: number
  steps: string[]
  loadingPlan: boolean
  currentPurchaseLink: string
  currentSliderValue: number
  updatePlanState: (updatedValues: Partial<PlansContext>) => void
  setvirtualChip: (virtualChip: boolean) => void
  setPortability: (portability: boolean) => void
  setSmartControl: (smartControl: boolean) => void
}

const defaultPlan: Plan = {
  id: 0,
  plano: "",
  valor: 0,
  nome_plano: "",
  ligacoes: "",
  sms: "",
  detalhamento: "",
  descricao_detalhamento: "",
  cor: "",
  internet_padrao: "",
}

const defaultPlansData: PlansContext = {
  plans: [],
  currentPlan: defaultPlan,
  planClass: "plan1",
  monthlyPrice: 39.9,
  annualPrice: 26.6,
  chosenPlan: "",
  virtualChip: false,
  portability: false,
  smartControl: false,
  planConfigs: false,
  planBonus: false,
  planIndex: 0,
  step: 0,
  steps: ["Seu Plano", "Informações", "Endereço", "Pagamento"],
  loadingPlan: true,
  currentPurchaseLink: "",
  currentSliderValue: 0,
  updatePlanState: () => {},
  setvirtualChip: (virtualChip: boolean) => {},
  setPortability: (portability: boolean) => {},
  setSmartControl: (smartControl: boolean) => {}
}

const PlanContext = createContext<PlansContext>(defaultPlansData)

export function usePlan() {
  return useContext(PlanContext)
}

export function PlanProvider({ children }: PropsWithChildren) {
  const [currentState, setCurrentState] = useState<PlansContext>(defaultPlansData)
  const [currentPath, setCurrentPath] = useState<string>()
  const location = useLocation()
  const colorPlanClasses = ["plan1", "plan2", "plan3", "plan4", "plan5"]

  const updatePlanState = (updatedValues: Partial<PlansContext>) => {
    setCurrentState((prevState) => ({
      ...prevState,
      ...updatedValues,
    }))
  }

  const setvirtualChip = useCallback((virtualChip: boolean) => {
    setCurrentState((prevState) => ({
      ...prevState,
      virtualChip: virtualChip,
      planConfigs: virtualChip || prevState.portability || prevState.smartControl
    }))
  }, [])

  const setPortability = useCallback((portability: boolean) => {
    setCurrentState((prevState) => ({
      ...prevState,
      portability: portability,
      planBonus: portability || prevState.smartControl,
      planConfigs: prevState.virtualChip || portability || prevState.smartControl
    }))
  }, [])

  const setSmartControl = useCallback((smartControl: boolean) => {
    setCurrentState((prevState) => ({
      ...prevState,
      smartControl: smartControl,
      planBonus: prevState.portability || smartControl,
      planConfigs: prevState.virtualChip || prevState.portability || smartControl
    }))
  }, [])

  useEffect(() => {
    const param = location.pathname.split("/")[1]
    setCurrentPath(param)
  }, [location])

  useEffect(() => {
    setCurrentState((prevState) => ({
      ...prevState,
      planClass: colorPlanClasses[prevState.planIndex],
      monthlyPrice: prevState.plans[prevState.planIndex]?.valor || prevState.monthlyPrice,
      annualPrice: ((prevState.plans[prevState.planIndex]?.valor || prevState.monthlyPrice) * 8) / 12,
    }))
    // eslint-disable-next-line
  }, [currentState.currentPlan])

  useEffect(() => {
    async function fetchPlans() {
      if (currentState.plans.length > 0) return
      try {
        const plansArray = await requests.plansList()

        if (Array.isArray(plansArray)) {
          const firstPlan = plansArray[0] || defaultPlan
          setCurrentState((prevState) => ({
            ...prevState,
            plans: plansArray,
            currentPlan: prevState.currentPlan?.id === 0 ? firstPlan : prevState.currentPlan,
            planClass: colorPlanClasses[prevState.planIndex],
            monthlyPrice: plansArray[0]?.valor || prevState.monthlyPrice,
            annualPrice: ((plansArray[0]?.valor || prevState.monthlyPrice) * 8) / 12,
            loadingPlan: false,
          }))
        } else {
          console.error("Resposta inesperada da API:", plansArray)
        }
      } catch (error) {
        console.error("Erro ao buscar planos:", error)
      }
    }

    if (currentPath === "" || currentPath === "vip") {
      fetchPlans()
    }
    // eslint-disable-next-line
  }, [currentPath])

  return (
    <PlanContext.Provider
      value={{
        ...currentState,
        updatePlanState,
        setvirtualChip,
        setPortability,
        setSmartControl
      }}
    >
      {children}
    </PlanContext.Provider>
  )
}