import { Box, Grid } from "@mui/material"
import { ISettingsItem } from "../Settings"

export default function BenefitsApps({ name, variables }: ISettingsItem) {
  const { image = [] } = variables || {}

  return (
    <Grid
      item
      className={name ? `${name} benefits-apps` : "benefits-apps"}
      sx={{
        display: 'inline-flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
      }}
    >
      {image.map((src, index) => (
        <Box
          key={index}
          className="bnfts-app-item"
          sx={{
            zIndex: image.length - index,
            marginLeft: index === 0 ? 0 : '-14px',
            position: 'relative',
            borderRadius: '50%',
            width: '34px',
            height: '34px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '4px'
          }}
        >
          <Box
            component="img"
            src={src}
            alt="Lista de aplicativos ilimitados"
            sx={{
              borderRadius: '50%',
              width: '100%',
              height: '100%',
            }}
          />
        </Box>
      ))}
    </Grid>
  )
}
