import { useEffect } from "react"
import { Box, Button, Grid, Skeleton, Typography } from "@mui/material"
import EastIcon from '@mui/icons-material/East'
import { useNavigate } from "react-router-dom"
import { useCategory } from "../../../Categories"
import { usePlan } from "../../../Plans"
import { useInitReq } from "../../../InitReq"
import { ISettingsItem } from "../../../Settings"
import ChoosePlanText from "../right/ChoosePlanText"
import SelectCategory from "../right/SelectCategory"
import BenefitsApps from "../../BenefitsApps"
import BenefitsList from "../../BenefitsList"
import SliderPlans from "../right/SliderPlans"
import Details from "./Details"

export default function CategoryChoice({ name, variables }: ISettingsItem) {
  const { categories, ctgrIndex, ctgrClass, currentCategory, updateState } = useCategory()
  const { loadingPlan, currentPlan } = usePlan()
  const { loadingChipReq } = useInitReq()
  const navigate = useNavigate()
  const defaultTextArray = ["Você escolhe seu perfil", "Feito para você, na medida"]
  const { text = defaultTextArray, image = [] } = variables || {}
  const mergedArray = text
    ? text.map((value, index) => (value !== undefined && value !== "" ? value : defaultTextArray[index]))
    : defaultTextArray
  const categoryCardTitleTextArray = { text: mergedArray.slice(0, 2) }
  const ctgrImages = {
    KIDS: image[2],
    BUSINESS: image[3],
    GAMER: image[4],
    INFLUENCER: image[5],
    ESTUDANTE: image[6],
  }
  const ctgrTitle = {
    KIDS: image[7],
    BUSINESS: image[8],
    GAMER: image[9],
    INFLUENCER: image[10],
    ESTUDANTE: image[11],
  }
  const detailsTextArray = { text: ['Faça como os melhores gamers', 'Escolha um plano que é sua cara'] }
  const bnftsTextArray = { text: ['Até 44GB', 'Ligações Ilimitados', 'SMS Gratuito', 'Filtro Anti-Spam', 'Aviso de não cobertura'] }
  const bnftsImagesArray = { image: image.slice(12) }

  useEffect(() => {
    const categoryNames = categories.map((category) => category.nome)
    updateState({ ctgrClass: categoryNames[ctgrIndex] })
    // eslint-disable-next-line
  }, [categories, ctgrIndex])

  function handleNext() {
    navigate('/configure-seu-plano')
  }

  const currentImage = ctgrImages[ctgrClass as keyof typeof ctgrImages]
  const currentTitleImage = ctgrTitle[ctgrClass as keyof typeof ctgrTitle]

  return (
    <Grid item className={name ? `${name} category-choice` : "category-choice"}>
      <Grid className="ctgr-container">
        <Box className="planCategoryCard">
          <Box component="img" className="logo" src={image[ctgrClass === "BUSINESS" ? 1 : 0]} alt="Logo" />
          <ChoosePlanText name="category-card-title" variables={categoryCardTitleTextArray} />
          <SelectCategory name="mobile" />
          <SliderPlans name="mobile" />
          <Box className="ctgr-description-area">
          <Typography className="perfil-text" variant="body1" dangerouslySetInnerHTML={{ __html: 'PERFIL' }} />
            {currentTitleImage ? <Box component="img" className="ctgr-title" src={currentTitleImage} alt="Título das categorias de planos" /> : null}
            <Typography className="description-text" variant="body1" dangerouslySetInnerHTML={{ __html: currentCategory.detalhes }} />
          </Box>
          <Box className="benefits">
            <BenefitsList variables={bnftsTextArray} />
            <BenefitsApps variables={bnftsImagesArray} />
          </Box>
          {currentImage ? <Box className="background-img" component="img" src={currentImage} alt="Categorias de planos" /> : null}
        </Box>
      </Grid>
      <Details name="desktop" variables={detailsTextArray} />
      {!loadingPlan && !loadingChipReq ? (
        <Box className={`choose-ctgr ${categories[ctgrIndex].nome}`}>
          <Button onClick={handleNext} className="choose-ctgr-button">
            <Typography variant="body1" dangerouslySetInnerHTML={{ __html: `${categories[ctgrIndex].nome}: ${currentPlan.plano}` }} />
            <EastIcon />
          </Button>
        </Box>
      ) : (
        <Skeleton variant="rectangular" className="skltn-choose-ctgr" animation="wave" />
      )}
    </Grid>
  )
}
